import React from 'react';
import {Link
 } from "react-router-dom";
//import { showLoading, hideLoading } from 'react-redux-loading-bar'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare,faDotCircle } from '@fortawesome/free-solid-svg-icons'
import Header from '../Shared/Header';
//import Navigation from '../Shared/Navigation';
// import bannerimg from '../../Usefull/images/subscription-banners.jpg'
import garbagedata from '../../Usefull/images/garbagedata.png'
import datafilter from '../../Usefull/images/datafilter.png'
import dashboard from '../../Usefull/images/dashboard.png'
import download from '../../Usefull/images/download.png'
import subscription from '../../Usefull/images/subscription.png'
import accountsetup from '../../Usefull/images/accountsetup.png'
import cheque from '../../Usefull/images/cheque.png'
import netbanking from '../../Usefull/images/netbanking.png'
import demanddraft from '../../Usefull/images/demanddraft.png'
import wallet from '../../Usefull/images/wallet.png'
import paypal from '../../Usefull/images/paypal.png'
import dccard from '../../Usefull/images/dccard.png'
import razorpay from '../../Usefull/images/razorpay.png'
import upi from '../../Usefull/images/upi.png'
import dashimg from '../../Usefull/images/dash_img.png'
import imgusaflag from '../../Usefull/images/usa_flag.png'
import imgbcg from '../../Usefull/images/client/bcg.jpg'
import imgbinani from '../../Usefull/images/client/binani.jpg'
import imggodrej from '../../Usefull/images/client/godrej.jpg'
import imgindianoil from '../../Usefull/images/client/indian-oil.jpg'
//import imgindusind from '../../Usefull/images/client/indusind.jpg'
import imgitc from '../../Usefull/images/client/itc.jpg'
import imgjindalsteel from '../../Usefull/images/client/jindal-steel.jpg'
import imgmilton from '../../Usefull/images/client/milton.jpg'
import imgpaharpur from '../../Usefull/images/client/paharpur.jpg'
import imgphilips from '../../Usefull/images/client/philips.jpg'
import imgrelience from '../../Usefull/images/client/relience.jpg'
import imgtata from '../../Usefull/images/client/tata.jpg'
import {startOfDay,addMonths} from 'date-fns';
import { Helmet } from 'react-helmet';
//import Accordion from 'react-bootstrap/Accordion'



import { callApi,APIcustomerID } from '../../Utilities/Api'
// const Constant.APiURl="http://localhost:3000/";
import * as Constant from '../../Utilities/Constant';

class Home extends React.Component{

    constructor(props) {
        super(props);
        this.state = {PlanList: [],
            isautho:false,
            FirstName:'',
            LastName:'',
            EmailID:'',
            MobileNo:'',
            Password:'',
            Subsid:0
         
      };
      

       
      }
      
      componentDidMount() {






       // dispatch(showLoading())
        // do long running stuff
        // const requestDatat={customerid:1}
        //       const usaProfileDetailst=callApi("POST",requestDatat,Constant.USDATAEMAIL);
        //       usaProfileDetailst.then((responseDatam)=>{
        //           console.log(responseDatam);
        //       });
        //   console.log(APIcustomerID());
          if(APIcustomerID()!=null)
          {
              this.setState({isautho:true})
              const requestData={customerid:APIcustomerID()}
              const usaProfileDetails=callApi("POST",requestData,Constant.USDATAPROFILEDETAILS);
              usaProfileDetails.then((responseData)=>{
  
             
              responseData.DataResponse.forEach(team => 
                {
                  
                  
                  this.setState({FirstName:team.FirstName,
                   LastName:team.LastName,
                   Password:team.Password,
                  
                   EmailID:team.EmailID,
                 
                   MobileNo:team.MobileNo});
                 
  
                    
              });
          
                  });
          }
        this.planlistfunction();
        const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
          
    //dispatch(hideLoading())
        }
    
      handleSubmit=(data,Ctype,PlanName)=> 
      {
        var isSubmitting=false;
        if(this.state.isautho)
        {
        var Records = 0;
        var Cost = 0;
        var SubscriptionID = 0; 
        var validity = 0;
        if (data === 1)
        {
            Records = 100;
            if(Ctype==="INR")
            {
            Cost = 0;
            }
            else
            {
                Cost = 0;
            }
            SubscriptionID = 1;
            validity = 1;
        }
        if (data === 2)
        {
            Records = 4000;
            if(Ctype==="INR")
            {
                Cost = 5000;
            }
            else
            {
                Cost = 80 ;
            }
            
            SubscriptionID = 2;
            validity = 6;
        }
        if (data === 3)
        {
            Records = 10000;
            if(Ctype==="INR")
            {
                Cost = 10000;
            }
            else
            {
                Cost = 150 ;
            }
           
            SubscriptionID = 3;
            validity = 12;
        }
        if (data === 4)
        {
            Records = 25000;
            if(Ctype==="INR")
            {
                Cost = 25000;
            }
            else
            {
                Cost = 350 ;
            }
          
            SubscriptionID = 4;
            validity = 24;
        }
        if (data === 5)
        {
            Records = 50000;
            if(Ctype==="INR")
            {
                Cost = 30000;
            }
            else
            {
                Cost = 400;
            }
           
            SubscriptionID = 5;
            validity = 30;
        }

        var StartDate = startOfDay(new Date(),1);
        //console.log(StartDate);
        var Enddate =addMonths(new Date(),validity);
        var FirstName=this.state.FirstName;
        var EmailID=this.state.EmailID;
        var Password=this.state.Password;
        var TaxAmount=(Cost*0.18);
        var amountv = Cost; 
        if(Ctype==="INR")
        {
            amountv=Cost+TaxAmount;
        }
        var amountv = amountv * 100; 
        

        var options = {
            description: 'Seair Exim Solution',
            image: 'https://www.seair.co.in/Content/NewDashboard/images/logo.png',
            currency: Ctype,
             key: 'rzp_live_C6RErc5hOvRHxz',
            //key: 'rzp_test_4qcuFI3ythjZt4',
            //'rzp_test_4qcuFI3ythjZt4', // Your api key
            amount: amountv,
            order_id:'',
            name: this.state.FirstName+' '+this.state.LastName,
            handler: function (response) {

                const requestDataResp = {
                    //orderCreationId: order_id,
                    razorpay_signature: response.razorpay_signature,
                    razorpay_order_id: response.razorpay_order_id,
                    transactionid: response.razorpay_payment_id,
                    transactionamount: amountv

                };
                
               
               
         //check and update to database
                const OrderUpdateResponse=callApi("POST",requestDataResp,Constant.USDATACREATEORDERPAYMENT);
                OrderUpdateResponse.then((OrderStatusData)=>{
                    if(OrderStatusData.data==="success")
                    {

                    //save in to database 
                    const requestData={CustomerID:APIcustomerID(),SubscriptionID:SubscriptionID,StartDate:StartDate,EndDate:Enddate,LastUpdate:StartDate,Records:Records,Cost:Cost,CostType:Ctype,IsPaid:true,DMLFlag:'I',SubsType:'USA',PaymentResponse:response,PlanName:PlanName,username:FirstName,EmailID:EmailID,password:Password}
                    const usaDataCount=callApi("POST",requestData,Constant.USDATACREATESUBSCRIPTION);
                    usaDataCount.then((responseData)=>{
                    if(responseData.DataResponse.error===null)
                    {
                        // console.log(responseData.DataResponse.Data.token.Subsid);
                        // alert("success");


                            //for testing PDF File/
                            const requestDataPDF={CustomerID:APIcustomerID(),Subsid:responseData.DataResponse.Data.token.Subsid}
                            const usaProfileDetailsPDF=callApi("POST",requestDataPDF,Constant.USDATAINVOICEPDF);
                            usaProfileDetailsPDF.then((responseDataPDF)=>{
                                
                            });

                            //end testing

                        window.location.href = "/app/invoices";   
                      

                    }
                    });
                }
                else
                {
                    //just leave same page
                }


                    
                 
                });

               
                
            },
            prefill: {
              email: this.state.EmailID,
              contact: this.state.MobileNo,
              name: this.state.FirstName+' '+this.state.LastName,
            },
            theme: {color: '#1E43DF'}
          }

        //for opup and creating order on razorpay only pass amount and currency type
        const requestDataOrder={amount:amountv,CostType:Ctype}
        const OrderCallResponse=callApi("POST",requestDataOrder,Constant.USDATACREATEORDER);
        OrderCallResponse.then((OrderresponseData)=>{
            options.order_id = OrderresponseData.id;
            options.amount = OrderresponseData.amount;
           let rzp = new window.Razorpay(options);
           rzp.open();
        });


        
    }
    else
    {
       
    }
       
    //e.preventDefault();
     
      }
     
//console.log(this.state.PlanFeature);

    async planlistfunction()
    {
        const requestData={}
        const usaPlanList=callApi("GET",requestData,Constant.USDATAPLANLIST);
        usaPlanList.then((responseData)=>{
        this.setState({PlanList:responseData.DataResponse});
          //console.log(this.state.PlanList);
            });

           
        
          //console.log(this.state.PlanFeature);
    }

    render(){
        return(
            <div className="App bg-bg-white">
            <Helmet>
                <title>USA Import Data, USA data Subscription Plans and Pricing Online</title>
                <meta name="description" content="Seair Exim Solutions offers various online USA data plans as USA export Import data, US import data, and US data of 80+ global countries with customs shipment details of importers in USA and exporters at affordable prices." />
            </Helmet>
                <div>
                
                {/* <FontAwesomeIcon icon={faTable} />
                    <FontAwesomeIcon icon={faCoffee} /> */}
                </div>
            <Header />
            
          

           
        <section className="sec_pad bg-light">
    <div className="container">
        <div className="row">
            <div className="col-md-12 text-center">
                <h1 className="sec_heading h3"> SUBSCRIBE TO OUR CUSTOMIZED DASHBOARD ESPECIALLY DESIGNED FOR YOUR SPECIFIC REQUIREMENT</h1>
                <p>
                    Read our
                    
                    <a href="https://www.seair.co.in/faq.aspx" target="_blank"> FAQ </a>
                      for more information about our services and policy.
                </p>
            </div>
            {(this.state.PlanList.length > 0) ? this.state.PlanList.map((item, index) => {
                                              var PlanCss
                                              var PlanRecomendation
                                              if(item.Planid===2)
                                              {
                                                PlanCss="subscription_box";
                                                PlanRecomendation=(<h4>{item.PlanName}</h4>)
                                                
                                            
                                              }
                                              else if(item.Planid===3)
                                              {
                                                PlanCss="subscription_box executive";
                                                PlanRecomendation=(<h4>{item.PlanName}</h4>)
                                              }
                                              else if(item.Planid===4)
                                              {
                                                PlanCss="subscription_box business";
                                                PlanRecomendation=(<div className="recomm_plan">
                                                <h4>{item.PlanName}</h4>
                                                <span>Recommended</span>
                                            </div>)
                                              }
                                              else if(item.Planid===5)
                                              {
                                                PlanCss="subscription_box corporate";
                                                PlanRecomendation=(<h4>{item.PlanName}</h4>)
                                              }
                                              else{ PlanCss="subscription_box";}


                                              return (
    
                                                  <div className="col-lg-3 col-sm-6">
    
                                                      <div className={PlanCss}>
                                                      
                                                          {/* <h4>{item.PlanName} 
                                                          
                                                          
                                                          </h4> */}
                                                          {PlanRecomendation}
                                                          <h5>{item.PlanAmountINR} INR</h5>
                                                          <p>{item.PlanAmountUSD} USD</p>
                                                          {/* <p>{item.Data}</p> */}
                                                          <ul>
                                                              {/* {
                                                                  item.Data.forEach((item1, i) => 
                                                                  {
                                                                
                                                                 <li>{item1.FeatureName} <p>{item1.FeatureValue}</p></li>
                                                                  }
                                                                  )
                                                                  // we push a JSX element containing a value to our 'myArrMadeFromForEach' because `.forEach()` does not return any value, nor does it mutate the array on which it is called
    
                                                              } */}
                                                          {item.Data[0].map( (item1, index) => {
                                                              
                        return(
                            <li>{item1.FeatureName} <p>
                                 <div dangerouslySetInnerHTML={{ __html: item1.FeatureValue }} />
                               </p></li>)
                        
                         })  }
    
                                                 
                                                          </ul>
                                                          {this.state.isautho ? (
                                                              <div>
                          <button type="submit" className="blue_btn rounded-pill"  onClick={data=>this.handleSubmit(item.Planid,"INR",item.PlanName)}>PAY IN INR</button>
                          <button type="submit" className="blue_btn rounded-pill" onClick={data=>this.handleSubmit(item.Planid,"USD",item.PlanName)}>PAY IN USD</button>
                          </div>
                        ) : (
                            <Link  to="/account/login" className="blue_btn rounded-pill" >
                            BUY
                            </Link>
                        )}
                                                          
                                                          {/* @Html.ActionLink("BUY", "USPlanSubmit", new {id = item.USPlanID}, new { @class = "btn_default" }) */}
                                                      </div>
    
                                                  </div>
    
    
                                              )
                                          }) : <div>Loading...</div>}
          
        </div>
    </div>
</section>
        <section className="sec_pad bg-white">
    <div className="container">
        <div className="row" id='table'>
            <div className="col-md-12">
                <h3 className="sec_heading">Features of Plan</h3>
            </div>
            <div className="col-lg-4 col-sm-6 mt-4">
                <div className="plan_featbox first">
                    <h6>
                        Track Your Product
                    </h6>
                    <ul className="fa-ul">
                        <li>
                            <span className="fa-li">
                            <FontAwesomeIcon icon={faCheckSquare} />
                                </span> Real-time Shipment record
                        </li>
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={faCheckSquare} /></span> Current Buyers
                        </li>
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={faCheckSquare} /></span> Live Suppliers
                        </li>
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={faCheckSquare} /></span> Used HS Codes
                        </li>
                    </ul>
                </div>
            </div>
            <div className="col-lg-4 col-sm-6 mt-4">
                <div className="plan_featbox second">
                    <h6>
                        Reports based on Analysis
                    </h6>
                    <ul className="fa-ul">
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={faCheckSquare} /></span> Quantity Based Graphs
                        </li>
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={faCheckSquare} /></span> Dedicated Shipment table
                        </li>
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={faCheckSquare} /></span> Specified Product Reports
                        </li>
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={faCheckSquare} /></span> Business mapping reports
                        </li>
                    </ul>
                </div>
            </div>
            <div className="col-lg-4 col-sm-6 mt-4">
                <div className="plan_featbox third">
                    <h6>
                        Market Trends
                    </h6>
                    <ul className="fa-ul">
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={faCheckSquare} /></span> HS Codes Analysis
                        </li>
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={faCheckSquare} /></span> Daily Trade Analysis
                        </li>
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={faCheckSquare} /></span> Country Wise Reports
                        </li>
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={faCheckSquare} /></span> product Segmentation.
                        </li>
                    </ul>
                </div>
            </div>
            <div className="col-md-12 mt-4">
                <div className="paln_featnote">
                    <h5 className="sec_heading">Highlights of what we offer in our plans</h5>
                    <ul className="fa-ul">
                        <li><span className="fa-li">
                        <FontAwesomeIcon icon={faDotCircle} />
                            </span> Join our subscription plan, specially designed for the USA, built keeping in mind the market trends and requirements.
                        </li>
                        <li><span className="fa-li"><FontAwesomeIcon icon={faDotCircle} /></span> Our different plans enable you to save and download the import-export data records as per HS Code, buyer, and items. So, you can access the data on import-export trade anytime at your convenience.           </li>
                        <li><span className="fa-li"><FontAwesomeIcon icon={faDotCircle} /></span>	We have customizable plans depending upon your needs. So you can pay only for just what you need. </li>
                        <li><span className="fa-li"><FontAwesomeIcon icon={faDotCircle} /></span> You get the option to download data in a specific plan based on what subscription you have opted to buy.</li>
                        <li><span className="fa-li"><FontAwesomeIcon icon={faDotCircle} /></span>Data is collected for the USA, extracting from official sources, allowed to be disclosed in a public domain.</li>
                        <li><span className="fa-li"><FontAwesomeIcon icon={faDotCircle} /></span> 	The above prices of plans exclude  <strong>18% GST.</strong></li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</section>
    <section className="sec_pad bg-light">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <h3 className="sec_heading text-center">
                    Seair’s procedure of delivering you the churned Import Export data
                </h3>
                <p className="text-center">
                    We have a simple yet effective method to fulfill all your needs
                </p>
            </div>
            <div className="col-md-12">
                <ul className="process">
                    <li>
                        <img src={garbagedata} alt="Raw Cluttered data" />
                        <p>
                            Raw Cluttered data
                        </p>
                    </li>
                    <li>
                        <img src={datafilter} alt="Data Processing"  />
                        <p>
                            Data Processing
                        </p>
                    </li>
                    <li>
                        <img src={dashboard} alt="Dashboard" />
                        <p>Dashboard</p>
                    </li>
                    <li>
                        <img src={download} alt="Save & download" />
                        <p>
                            Save & download
                        </p>
                    </li>
                    <li>
                        <img src={subscription} alt="Subscription" />
                        <p>
                            Subscription
                        </p>
                    </li>
                    <li>
                        <img src={accountsetup} alt="Instant and easy account setup" />
                        <p>
                            Instant and easy account setup
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    </section>
    <section className="sec_pad bg-white">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <h3 className="sec_heading text-center">
                    Our payment platform is 100% safe; you can securely pay for our subscription plans.
                </h3>
                <p className="text-center text_grey">
                    We accept all modes of payments.
                </p>
            </div>
        </div>
        <div className="row mt-4">
            <div className="col-md-12">
                <ul className="payment_method">
                    <li>
                        <div className="pay_methbox">
                            <span>
                                <img src={cheque} alt="Cheque" />
                            </span>
                            <p>Cheque</p>
                        </div>
                    </li>
                    <li>
                        <div className="pay_methbox">
                            <span>
                                <img src={netbanking} alt="Net Banking" />
                            </span>
                            <p>Net Banking</p>
                        </div>
                    </li>
                    <li>
                        <div className="pay_methbox">
                            <span>
                                <img src={demanddraft} alt="Demand Draft" />
                            </span>
                            <p>Demand Draft</p>
                        </div>
                    </li>
                    <li>
                        <div className="pay_methbox">
                            <span>
                                <img src={wallet} alt="Wallet" />
                            </span>
                            <p>Wallet</p>
                        </div>
                    </li>
                    <li>
                        <div className="pay_methbox">
                            <span>
                                <img src={paypal} alt="Paypal" />
                            </span>
                            <p>Paypal</p>
                        </div>
                    </li>
                    <li>
                        <div className="pay_methbox">
                            <span>
                                <img src={dccard} alt="Debit &amp; Credit Card" />
                            </span>
                            <p>Debit &amp; Credit Card</p>
                        </div>
                    </li>
                    <li>
                        <div className="pay_methbox">
                            <span>
                                <img src={razorpay} alt="Razorpay" />
                            </span>
                            <p>Razorpay</p>
                        </div>
                    </li>
                    <li>
                        <div className="pay_methbox">
                            <span>
                                <img src={upi} alt="UPI" />
                            </span>
                            <p>UPI</p>
                        </div>
                    </li>

                </ul>
            </div>
        </div>
    </div>
    </section>

    <section className="sec_pad bg-white ec_shadow">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <h3 className="sec_heading">Payment Mode</h3>
                <p>
                    You can pay through different payment modes for our subscription plans.
                </p>
            </div>
            <div className="col-lg-6 col-md-5 mt-4">
                <div className="payment_block">
                    <h6>Credit/Debit Card</h6>
                    <p>
                        <strong>Payment through cheque or Demand Draft.</strong>
                        If you are paying through Demand draft, you can send cheque or demand draft in the name of “SEAIR EXIM SOLUTIONS” payable at New Delhi at the given address:-
                    </p>
                    <h6>
                        DEPT. Sales
                    </h6>
                    <p>
                        <strong>SEAIR EXIM SOLUTIONS</strong>
                        Plot C2, Office No 2, First Floor, Mahindra park, Opp. GRID Office C1 Janakpuri, West Delhi 110059, India
                    </p>
                    <h6 className="mt-4">For Offline Plan</h6>
                    <p className="mb-0">
                        <strong>You can Reach out to us for any query</strong> <a href="tel:+919667735607">+91-9667735607</a>
                        <span className="d-block mt-2">or Write to us <a href="mailto:info@seair.co.in">info@seair.co.in</a></span>
                    </p>
                </div>
            </div>
            <div className="col-lg-6 col-md-7 mt-4">
                <div className="payment_block">
                    <h6>Account Deposit or Account Transfer</h6>
                    <ul>
                        <li>
                            <span>
                                Account Name
                            </span>
                            SEAIR EXIM SOLUTIONS
                        </li>
                        <li>
                            <span>
                                Bank Name
                            </span>
                            HDFC BANK , New Delhi
                        </li>
                        <li>
                            <span>
                                Account No
                            </span>
                            00898190000076
                        </li>
                        <li>
                            <span>
                                IFSC CODE
                            </span>
                            Hdfc0000089
                        </li>
                        <li>
                            <span>
                                SWIFT CODE
                            </span>
                            HDFCINBB
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<section className="sec_pad lblue_sec">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-lg-5 col-md-6">
                <h3 className="sec_heading sec_headLineh text_blue sec_headlbold">
                    Find the import and export data at once
                </h3>
                <p className="sec_text">
                    We hire skilled and qualified data researchers for our company. They extract and maintain the
                    correct data of all the importers and exporters with all the details. Further, the website of our
                    company contains all the details of every import and export.
                </p>
                <p>
                    In addition to that, you can track your shipment, amount, quantity, date of shipments, and
                    other details on our official website.
                </p>
                <div className="btn_wthlnk mt-4">
                    <Link to="/account/login" className="blue_btn">Login</Link>
                    <Link to="/account/register" className="linknor">Register here</Link>
                    
                </div>
            </div>
            <div className="col-lg-7 col-md-6 text-center mt-4 mt-md-0">
                <div className="dasbord_imgho">
                    <img src={dashimg} className="img-fluid lazyloaded" alt="Register here" />
                    <a href="javascript:void(0)" alt="Video" className="dashboardvideo">
                        <i className="fas fa-play" title="" data-toggle="tooltip" data-original-title="Have a look at the Dashboard"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<section className="sec_pad bg-white">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <h3 className="sec_heading">
                    USA Import Data Dashboard
                </h3>
            </div>
        </div>
        <div className="row mt-3">
            
            <div className="col-md-12">
                <div className="flag_box">
                    <img src={imgusaflag} alt="" />
                    <div className="flag_det">
                        <h4>USA</h4>
                        <p>
                            Experience a dedicated dashboard for our consumers from USA about its Import-Export report based analytical Data.
                        </p>
                        
                    </div>
                </div>
                <div className="flag_table table-responsive">
                    <table className="table">
                        <tbody>
                            <tr>
                                <th>
                                    Bill of Lading
                                </th>
                                <td>
                                    A UNIQUE NUMBER ASSIGNED
                                    TOPARTICULAR SHIPMENT BY
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Vessel Name
                                </th>
                                <td>
                                    NAME OF CARGO
                                    TRANSPORTING SHIPMENT
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Loading Port
                                </th>
                                <td>
                                    THE PORT OF EXPORTING COUNTRY
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Unloading Port
                                </th>
                                <td>
                                    THE PORT WHERE PRODUCT
                                    HAS LANDED IN US
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Quantity
                                </th>
                                <td>
                                    THE VOLUME OF PRODUCT
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Quantity Unit
                                </th>
                                <td>
                                    HOW THE PRODUCT
                                    HAS BEEN MEASURED
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Shipper Name
                                </th>
                                <td>
                                    Supplier Company Name
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Shipper Adress
                                </th>
                                <td>
                                    Supplier Company Adress
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Consignee Name
                                </th>
                                <td>
                                    US Importing Company
                                </td>
                            </tr>
                          
                        </tbody>
                    </table>
                </div>
                {/* <a href="#" className="viewall text-center d-block mt-3 mt-md-5">View All <i className="fas fa-arrow-circle-right"></i></a> */}
            </div>
        </div>
    </div>
</section>
{/* <section className="sec_pad bg-light">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <h3 className="sec_heading">Read our FAQs for your general queries:</h3>
            </div>
            <div className="col-md-12">
                <div className="faq_box active">
                    <div className="faqclick">
                        <h5 className="sec_heading">
                            General Questions
                        </h5>
                        <i className="fas fa-chevron-down"></i>
                    </div>
                    <div className="faq_detail">
                        <div className="faw_detbox">
                            <h6 className="sec_heading"> What is SEAIR Exim Solution</h6>
                            <p>
                                SEAIR Exim Solutions is an Indian founded export-import data research firm deals in import-export intelligence information of over 80+ nations all around the world. We have the dream to create a platform for other agencies to determine hidden prospects and multiply their revenues by using our valuable analysis.
                            </p>
                        </div>
                        <div className="faw_detbox">
                            <h6 className="sec_heading"> What types of data do you provide?</h6>
                            <p>
                                Our data providing company provides 3 kinds of data to our clients. Check out below-
                            </p>
                            <ul className="sec_listdata mb-0 mt-3">
                                <li>
                                    Online USA and INDIA data.
                                </li>
                                <li>
                                    Offline data 80+ countries.
                                </li>
                                <li>
                                    Analysis Report
                                </li>
                            </ul>
                        </div>
                        <div className="faw_detbox">
                            <h6 className="sec_heading">
                                What’s the difference between online data, offline data and Analysis report?
                            </h6>
                            <ul className="sec_listdata mt-3 mb-0">
                                <li>
                                    <strong>Online USA AND INDIA data:–</strong> We have a well-designed dashboard for USA and India Exim database, by choosing your selected plan through our portal you can explore millions of records. For more details, please visit our subscriptions plan page.
                                </li>
                                <li>
                                    <strong>Offline Data 80+ Countries:-</strong> It is helpful whenever you wish to explore export and import market size of any nation. Also, this is helpful for research into your export-import business. The data contains all the details of consignment even some time including the names of exporter and importer. It will support you to recognize the market demand for any specific good along with its import-export value or capacity.
                                </li>
                                <li>
                                    <strong>Analysis Report:-</strong> It is prepared by professional data analysts according to your needs. This is examined by model; brand and other specifications of an item other than that it will give you an in-depth analysis of segmentation, market mapping, value analysis, volume count etc. The analysis report is totally based on your given specification and inputs, we shall prepare an exact report as per your needs.
                                </li>
                            </ul>
                        </div>
                        <div className="faw_detbox">
                            <h6 className="sec_heading">What kind of data should I purchase?</h6>
                            <p>
                                Well, it will depend on your needs-
                            </p>
                            <ul className="sec_listdata mb-0 mt-3">
                                <li>
                                    If you only want data to know about the actual market size of any country and confidential info of items like quantity, price, HS code, names of trader then offline data would be a great option for you.
                                </li>
                                <li>
                                    If you wish to explore the entire market trends of your goods and also want to function at more on reporting based system then you should try analysis report.
                                </li>
                                <li>
                                    If you just need the USA Import/ Export data and Indian Statistical data to understand the particular market trends in a specified country, then online data option is for you. But, online option is only applicable for subscribed users so you need to register with our web page and choose a subscription plan. You will get it in a great shape along with designed dashboard in order to cater your all needs of data and Analysis (Graphs, tabs).
                                </li>
                            </ul>
                        </div>
                        <div className="faw_detbox">
                            <h6 className="sec_heading">
                                How can data report help me?
                            </h6>
                            <p>
                                80+ nation’s global data is present at a single click that allows you to find out the reasonable and moneymaking products, target marketplace, trustworthy buyers, and suppliers
                            </p>
                        </div>
                        <div className="faw_detbox">
                            <h6 className="sec_heading">
                                How do you collect the import and export data information?
                            </h6>
                            <p>
                                Our data collecting sourcing is handled by our global partners, we collect Exim base from different Zones of globe, where particular countries have published Exim information for the public view ( in any Mode), We are strictly engaged with Private firms for collecting, sharing, mining, analyzing export/Import database.
                            </p>
                            <p className="mt-3">
                                <strong>Note:-</strong> We have nowhere any connection directly or indirectly with Indian custom or their sister bodies for data or information collection.
                            </p>
                        </div>
                        <div className="faw_detbox">
                            <h6 className="sec_heading">
                                How can I find out HS Code for my item?
                            </h6>
                            <p>
                                If you actually wish to know the HS Code of your product or item, just visit <a href="https://www.seair.co.in/hs-code-search.aspx">https://www.seair.co.in/hs-code-search.aspx</a> After clicking on it, mention the name of your product in the box and click on the search button.
                            </p>
                        </div>
                        <div className="faw_detbox">
                            <h6 className="sec_heading">
                                What types of clients do you have?
                            </h6>
                            <p>
                                Topmost logistic agencies, exporters, importers, and research firms are present in our list of clients. Few of our clients are Philips, Milton, JCB, Binani, Dalmia, BCG, Yamaha, IndusInd, reliance  etc. See the list of other <a href="https://www.seair.co.in/key-clients.aspx">clients</a> from here
                            </p>
                        </div>
                        <div className="faw_detbox">
                            <h6 className="sec_heading">
                                How is import-export data report helpful for my business?
                            </h6>
                            <p>
                                Our data report assists exporters, importers, and other firms in several ways-
                            </p>
                            <ul className="sec_listdata mb-0 mt-3">
                                <li>
                                    Analyze and track shipments of your competitors
                                </li>
                                <li>
                                    Find out new emerging market along with potential importers and exporters
                                </li>
                                <li>
                                    Quickly understand the growth percentage of goods, market shares etc.
                                </li>
                                <li>
                                    Generates profitable and new leads from all over the world
                                </li>
                                <li>Provides accurate trade records to keep updated with overall trading business</li>
                                <li>Identify the regional and international trading trends</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="faq_box">
                    <div className="faqclick">
                        <h5 className="sec_heading">
                            Getting Started
                        </h5>
                        <i className="fas fa-chevron-down"></i>
                    </div>
                    <div className="faq_detail">
                        <div className="faw_detbox">
                            <h6 className="sec_heading">
                                What nations do you include in export-import data report?
                            </h6>
                            <p>
                                We include more than 80 nation’s export and import transactional records along with their trading partners. Some of the nations are Bolivia, China, Argentina, Ecuador, Indonesia, Pakistan, Sri Lanka, United States of America, Vietnam etc. For more please check <a href="https://www.seair.co.in/global-trade-data.aspx">Global Trade Data</a>.
                            </p>
                        </div>
                        <div className="faw_detbox">
                            <h6 className="sec_heading">
                                How can I obtain offline data ?
                            </h6>
                            <p>
                                You can obtain import-export data in excel sheet via e-mail, you can also get it through pen drive and CD.
                            </p>
                        </div>
                        <div className="faw_detbox">
                            <h6 className="sec_heading">
                                Can I get a sample report before purchasing data?
                            </h6>
                            <p>
                                Yes, you can obtain a sample report of data at free of cost. Get Import Export Data Sample Report
                            </p>
                        </div>
                        <div className="faw_detbox">
                            <h6 className="sec_heading">
                                How soon will I obtain revert after dropping a query?
                            </h6>
                            <p>
                                After filling a query form, you’ll get revert of your query within 4 hours in business days.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="faq_box">
                    <div className="faqclick">
                        <h5 className="sec_heading">
                            Payment Mode
                        </h5>
                        <i className="fas fa-chevron-down"></i>
                    </div>
                    <div className="faq_detail">
                        <div className="faw_detbox">
                            <h6 className="sec_heading">
                                What’s the subscription plan for import-export data report?
                            </h6>
                            <p>
                                We have four subscription plans to get import and export data report like Bronze, Silver, Gold, and Corporate. You can choose a plan according to your business requirements. For more information, <a href="https://www.seair.co.in/subscription-plans.aspx">click here</a>
                            </p>
                        </div>
                        <div className="faw_detbox">
                            <h6 className="sec_heading">
                                What are the payment methods?
                            </h6>
                            <p>
                                There are 4 types of payment methods that you can use to obtain export and import data report. For details, <a href="https://www.seair.co.in/subscription-plans.aspx">click here</a>
                            </p>
                        </div>
                        <div className="faw_detbox">
                            <h6 className="sec_heading">
                                After confirming payment, how soon will I get data?
                            </h6>
                            <p>
                                Mostly, you will get data report within twenty-four hours. But in a few cases, it will be provided in 2 to 3 days.
                            </p>
                        </div>
                        <div className="faw_detbox">
                            <h6 className="sec_heading">
                                Do you offer a money back guarantee?
                            </h6>
                            <p>
                                Yes, we offer a 100% money back guarantee. If you will not satisfy by our data report, we’ll refund back all your money.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="faq_box">
                    <div className="faqclick">
                        <h5 className="sec_heading">
                            About Data Report
                        </h5>
                        <i className="fas fa-chevron-down"></i>
                    </div>
                    <div className="faq_detail">
                        <div className="faw_detbox">
                            <h6 className="sec_heading">
                                What is the criterion of your data report?
                            </h6>
                            <p>
                                You’ll collect the data report on the basis of harmonized system code of your item or good. This will also vary according to the time duration and the nations you want to access such as yearly, monthly, and quarterly.
                            </p>
                        </div>
                        <div className="faw_detbox">
                            <h6 className="sec_heading">
                                How often is import-export database getting updated?
                            </h6>
                            <p>
                                We update an import-export database of most of the nations on each month. Database of a few countries is updated in 45-60 days that depends on port or country. We have an <a href="https://www.seair.co.in/indian-export-data.aspx">export-import database</a> from 2009 to the present year.
                            </p>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</section>
<Accordion defaultActiveKey="0">
  <Accordion.Item eventKey="0">
    <Accordion.Header>Accordion Item #1</Accordion.Header>
    <Accordion.Body>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
      velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
      cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
      est laborum.
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="1">
    <Accordion.Header>Accordion Item #2</Accordion.Header>
    <Accordion.Body>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
      velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
      cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
      est laborum.
    </Accordion.Body>
  </Accordion.Item>
</Accordion> */}
<section className="cleintsec  sec_pad ">
        <div className="container">
            <div className="row">
            <div className="col-md-12">
            <div className="cleintbox">
            <div className="headiman_data">
              <h2>High Volume of Trusted and Loyal Clients</h2>
              <p className="blckbb">We feel pride in offering our customers the wide range of products of the top leading
                brands of the world.</p>
            </div>

            <div className="cleintlist">
              <ul>
                <li>
                  <span>
                    <img src={imgbcg} alt="bcg"/>
                  </span>
                </li>
                <li><span><img src={imgbinani} alt="binani" /></span></li>
                <li><span><img src={imgbcg} alt="godrej" /></span></li>
                <li><span><img src={imggodrej} alt="indian-oil" /></span></li>
                <li><span><img src={imgindianoil} alt="indusind" /></span></li>
                <li><span><img src={imgitc} alt="itc"/></span></li>
                <li><span><img src={imgjindalsteel} alt="jindal-steel"/></span></li>
                <li><span><img src={imgmilton} alt="milton"/></span></li>
                <li><span><img src={imgpaharpur} alt="paharpur"/></span></li>
                <li><span><img src={imgphilips} alt="philips"/></span></li>
                <li><span><img src={imgrelience} alt="relience"/></span></li>
                <li><span><img src={imgtata} alt="tata"/></span></li>
              </ul>
            </div>
          </div>
          </div> 
                <div className="col-md-12 d-flex mt-3 mt-md-5">
                    <a href="https://www.seair.co.in/key-clients.aspx" target="_blank" className="sec_centrdlink m-auto">
                        View All
                    </a>
                </div>
            </div>
            </div>
        
    </section>
<section className="copy_right dark_sec">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <p>
                        Copyright © 2009 - 2025 <strong><a href="#">www.seair.co.in.</a></strong>  All Rights Reserved.
                    </p>

                </div>
            </div>
        </div>
    </section>
   
   
            </div>

            
        )
    }

}
export default Home;