import React, { Component } from 'react';
import Header from '../Shared/Header';
import Navigation from '../Shared/Navigation';
import { callApi } from '../../Utilities/Api'
// const Constant.APiURl="http://localhost:3000/";
import * as Constant from '../../Utilities/Constant';
//import RazorpayCheckout from 'react-native-razorpay';

{/* <script src="https://checkout.razorpay.com/v1/checkout.js"></script> */}
//seair key
// key_id	
// rzp_live_5WIuAnafp8oekq	
// key_secret OPcUn3fyEtR4zYtXjs3vS3hO
//testing key
//key_id,
//rzp_test_pJYFYD8bfFRaxN
//key_secret dR985teMc54HoKrf2xhGCJoI

//success@razorpay








export default class Subscription extends Component {

    constructor(props) {
      super(props);
      this.state = {PlanList: []
       
    };
     
    }
    
    componentDidMount() {

        this.planlistfunction();
        const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
     
      }
handleSubmit=(data)=>
    //handleSubmit(event) 
    {

        console.log(data)
        var Records = 0;
        var Cost = 0;
        var SubscriptionID = 0; 
        var validity = 0;
        if (data.Planid === 1)
        {
            Records = 100;
            Cost = 0;
            SubscriptionID = 1;
            validity = 1;
        }
        if (data.Planid === 2)
        {
            Records = 4000;
            Cost = 5000;
            SubscriptionID = 2;
            validity = 6;
        }
        if (data.Planid === 3)
        {
            Records = 10000;
            Cost = 10000;
            SubscriptionID = 3;
            validity = 12;
        }
        if (data.Planid === 4)
        {
            Records = 25000;
            Cost = 25000;
            SubscriptionID = 4;
            validity = 24;
        }
        if (data.Planid === 5)
        {
            Records = 50000;
            Cost = 30000;
            SubscriptionID = 5;
            validity = 30;
        }
        var StartDate = new Date();


        const requestData={CustomerID:118513,SubscriptionID:SubscriptionID,StartDate:StartDate,EndDate:StartDate,LastUpdate:StartDate,Records:Records,Cost:Cost,CostType:'INR',IsPaid:'false',DMLFlag:'I',SubsType:'USA'}
        const usaDataCount=callApi("POST",requestData,Constant.USDATACREATESUBSCRIPTION);
        usaDataCount.then((responseData)=>{
          this.setState({TotalRecords:responseData.dataCount})
        });

        

    //  var order_id=40000;
    //     var options = {
    //         description: 'Seair Exim Solution',
    //         image: 'https://www.seair.co.in/Content/NewDashboard/images/logo.png',
    //         currency: 'INR',
    //         key: 'rzp_test_pJYFYD8bfFRaxN', // Your api key
    //         amount: '100',
    //         name: 'Navlesh Kumar',
    //         handler: async function (response) {
    //             console.log(response);
    //             const data = {
    //                 orderCreationId: order_id,
    //                 razorpayPaymentId: response.razorpay_payment_id,
    //                 razorpayOrderId: response.razorpay_order_id,
    //                 razorpaySignature: response.razorpay_signature,
    //                 razorpayOrderStatus: response.razorpay_order_status,

    //             };
    //             console.log(data);
    //             //const result = await axios.post("http://localhost:5000/payment/success", data);

    //             //alert(result.data.msg);
    //         },
    //         prefill: {
    //           email: 'navlesh@gmail.co.in',
    //           contact: '9717388378',
    //           name: 'Navlesh Kumar'
    //         },
    //         theme: {color: '#F37254'}
    //       }
    //       let rzp = new window.Razorpay(options);
    //         rzp.open();

     // event.preventDefault();
      
     
    }
    handleBookingPaymentResponse = (res) => {
        console.log(res);
        // this.setState({ loading: true });
        // let selectedTariff = this.state.selectedTariff;
        // let bookingData = {
        //   "name": "Booking",
        //   "checkinDate": this.state.checkin + "T12:00:00",
        //   "checkoutDate": this.state.checkout + "T12:00:00",
        //   "bookingAmount" : this.state.bookingAmount,
        //   "order_id" : res.razorpay_order_id,
        //   "payment_id" : res.razorpay_payment_id,
        //   "guest" : this.state.guest,
        //   "hotel_id" : this.state.site._id,
        //   "siteAddress" : this.state.site.siteAddress
        // };
      
        // ConfirmBooking(bookingData)
        // .then(res => {
        //   // Handle successful booking.
        // })
        // .catch(err => {
        //   // Handle booking failure.
        // });        
      }
    
    async planlistfunction()
    {
        const requestData={}
        const usaPlanList=callApi("GET",requestData,Constant.USDATAPLANLIST);
        usaPlanList.then((responseData)=>{
        this.setState({PlanList:responseData.DataResponse});
        //  console.log(this.state.PlanList);
            });
        
          //console.log(this.state.PlanFeature);
    }
  
  
    render() 
    {
    //   this.searchDataBuyer();
      return (
        <div className="App">
        <Header />
        <Navigation/> 
        <section className="dash_sec">
        <div className="container-fluid">
            <div className="row">
        <div className="col-md-12">
            <div className="sec_block">
                <div className="sec_blockhead">
                    <h5>To get Search result choose any of the below Plan.</h5>
                </div>
                <div className="subsplan_dash">
                    <div className="row">
                                      {(this.state.PlanList.length > 0) ? this.state.PlanList.map((item, index) => {
                                          
                                          return (

                                              <div className="col-lg-3 col-sm-6">

                                                  <div className="subscription_box">
                                                      <h3>{item.PlanName}</h3>
                                                      <h4>{item.PlanAmountINR} INR</h4>
                                                      <p>{item.PlanAmountUSD} USD</p>
                                                      {/* <p>{item.Data}</p> */}
                                                      <ul>
                                                          {/* {
                                                              item.Data.forEach((item1, i) => 
                                                              {
                                                            
                                                             <li>{item1.FeatureName} <p>{item1.FeatureValue}</p></li>
                                                              }
                                                              )
                                                              // we push a JSX element containing a value to our 'myArrMadeFromForEach' because `.forEach()` does not return any value, nor does it mutate the array on which it is called

                                                          } */}
                                                      {item.Data[0].map( (item1, index) => {
                    return(
                        <li>{item1.FeatureName} <p>
                             <div dangerouslySetInnerHTML={{ __html: item1.FeatureValue }} />
                           </p></li>)
                    
                     })  }

                                             
                                                      </ul>
                                                      <button type="submit" className="btn_default" onClick={this.handleSubmit(item.Planid)}>BUY Plan</button>
                                                      {/* @Html.ActionLink("BUY", "USPlanSubmit", new {id = item.USPlanID}, new { @class = "btn_default" }) */}
                                                  </div>

                                              </div>


                                          )
                                      }) : <div>Loading...</div>}
                                      
                                  </div>
                              </div>
                {/* @if (ViewBag.ISpaid == "true")
                {
                    <div className="subsrecord">
                        <div className="row align-items-center">
                            <div className="col-lg-5 col-md-6">
                                <h6>Subscribe Records: <span>@ViewBag.Subscribedrecords</span></h6>
                            </div>
                            <div className="col-lg-5 col-md-6 mt-3 mt-md-0">
                                <h6>Used Records: <span>@ViewBag.Userdrecords</span></h6>
                            </div>
                            <div className="col-lg-2 col-md-12 mt-3 mt-lg-0">
                                <a href="/globaluser/invoices" className=" btn_default">Invoice</a>
                            </div>
                        </div>
                    </div>
                } */}
                {/* <div className="subsplan_dash">
                    <div className="row">
                        @foreach (var item in Model.Take(1))
                        {
                            if (item.USPlanID.ToString() == ViewBag.activeplanid && item.USPlanID > 1)
                            {
                                <input type="hidden" value="@item.USPlanID.ToString()" />
                                <input type="hidden" value="@ViewBag.activeplanid" />
                                <div className="col-lg-3 col-sm-6">
                                    <form method="post" action="USPlanSubmit">
                                        <div className="subscription_box disabled">
                                            <h3>@item.PlanName</h3>
                                            <h4>@item.PlanAmountINR INR</h4>
                                            <p>@item.PlanAmountUSD USD</p>
                                            <ul>
                                                @foreach (var item1 in item.USPLANFEATURELIST.Take(5))
                                                {
                                                    if (@item1.FeatureName == "")
                                                    {

                                                    }
                                                    else
                                                    {
                                                        <li>@item1.FeatureName <p>@Html.Raw(item1.FeatureValue)</p></li>
                                                    }

                                                }
                                            </ul>
                                            @Html.ActionLink("BUY", "USPlanSubmit", new { id = item.USPlanID }, new { @class = "btn_default" })
                                        </div>
                                    </form>
                                </div>
                            }
                            else
                            {
                                <div className="col-lg-3 col-sm-6">
                                    <form method="post" action="USPlanSubmit">
                                        <div className="subscription_box">
                                            <h3>@item.PlanName</h3>
                                            <h4>@item.PlanAmountINR INR</h4>
                                            <p>@item.PlanAmountUSD USD</p>
                                            <ul>
                                                @foreach (var item1 in item.USPLANFEATURELIST.Take(5))
                                                {
                                                    if (@item1.FeatureName == "")
                                                    {

                                                    }
                                                    else
                                                    {
                                                        <li>@item1.FeatureName <p>@Html.Raw(item1.FeatureValue)</p></li>
                                                    }

                                                }
                                            </ul>
                                            @Html.ActionLink("BUY", "USPlanSubmit", new { id = item.USPlanID }, new { @class = "btn_default" })
                                        </div>
                                    </form>
                                </div>
                            }
                        }
                        @foreach (var item in Model.Skip(1).Take(1))
                        {
                            if (item.USPlanID.ToString() == ViewBag.activeplanid && item.USPlanID > 1)
                            {
                                <input type="hidden" value="@item.USPlanID.ToString()" />
                                <input type="hidden" value="@ViewBag.activeplanid" />
                                <div className="col-lg-3 col-sm-6 mt-4 mt-sm-0">
                                    <form method="post" action="USPlanSubmit">
                                        <div className="subscription_box disabled">
                                            <h3>@item.PlanName</h3>
                                            <h4>@item.PlanAmountINR INR</h4>
                                            <p>@item.PlanAmountUSD USD</p>
                                            <ul>
                                                @foreach (var item1 in item.USPLANFEATURELIST.Take(5))
                                                {
                                                    if (@item1.FeatureName == "")
                                                    {

                                                    }
                                                    else
                                                    {
                                                        <li>@item1.FeatureName <p>@Html.Raw(item1.FeatureValue)</p></li>
                                                    }

                                                }
                                            </ul>
                                            @Html.ActionLink("BUY", "USPlanSubmit", new { id = item.USPlanID }, new { @class = "btn_default" })
                                        </div>
                                    </form>
                                </div>
                            }
                            else
                            {
                                <div className="col-lg-3 col-sm-6 mt-4 mt-sm-0">
                                    <div className="subscription_box">
                                        <form method="post" action="USPlanSubmit">
                                            <h3>@item.PlanName</h3>
                                            <h4>@item.PlanAmountINR INR</h4>
                                            <p>@item.PlanAmountUSD USD</p>
                                            <ul>
                                                @foreach (var item1 in item.USPLANFEATURELIST.Take(5))
                                                {
                                                    if (@item1.FeatureName == "")
                                                    {

                                                    }
                                                    else
                                                    {
                                                        <li>@item1.FeatureName <p>@Html.Raw(item1.FeatureValue)</p></li>
                                                    }

                                                }
                                            </ul>
                                            @Html.ActionLink("BUY", "USPlanSubmit", new { id = item.USPlanID }, new { @class = "btn_default" })
                                        </form>
                                    </div>
                                </div>
                            }
                        }
                        @foreach (var item in Model.Skip(2).Take(1))
                        {
                            if (item.USPlanID.ToString() == ViewBag.activeplanid && item.USPlanID > 1)
                            {
                                <input type="hidden" value="@item.USPlanID.ToString()" />
                                <input type="hidden" value="@ViewBag.activeplanid" />
                                <div className="col-lg-3 col-sm-6 mt-4 mt-lg-0">
                                    <div className="subscription_box disabled">
                                        <form method="post" action="USPlanSubmit">
                                            <div className="recomm_plan">
                                                <h3>@item.PlanName</h3>
                                                <span>Recommended</span>
                                            </div>
                                            <h4>@item.PlanAmountINR INR</h4>
                                            <p>@item.PlanAmountUSD USD</p>
                                            <ul>
                                                @foreach (var item1 in item.USPLANFEATURELIST.Take(5))
                                                {
                                                    if (@item1.FeatureName == "")
                                                    {

                                                    }
                                                    else
                                                    {
                                                        <li>@item1.FeatureName <p>@Html.Raw(item1.FeatureValue)</p></li>
                                                    }

                                                }
                                            </ul>
                                            @Html.ActionLink("BUY", "USPlanSubmit", new { id = item.USPlanID }, new { @class = "btn_default" })
                                        </form>
                                    </div>
                                </div>
                            }
                            else
                            {
                                <div className="col-lg-3 col-sm-6 mt-4 mt-lg-0">
                                    <div className="subscription_box">
                                        <form method="post" action="USPlanSubmit">
                                            <div className="recomm_plan">
                                                <h3>@item.PlanName</h3>
                                                <span>Recommended</span>
                                            </div>
                                            <h4>@item.PlanAmountINR INR</h4>
                                            <p>@item.PlanAmountUSD USD</p>
                                            <ul>
                                                @foreach (var item1 in item.USPLANFEATURELIST.Take(5))
                                                {
                                                    if (@item1.FeatureName == "")
                                                    {

                                                    }
                                                    else
                                                    {
                                                        <li>@item1.FeatureName <p>@Html.Raw(item1.FeatureValue)</p></li>
                                                    }

                                                }
                                            </ul>
                                            @Html.ActionLink("BUY", "USPlanSubmit", new { id = item.USPlanID }, new { @class = "btn_default" })
                                        </form>
                                    </div>
                                </div>
                            }
                        }
                        @foreach (var item in Model.Skip(3).Take(1))
                        {
                            if (item.USPlanID.ToString() == ViewBag.activeplanid && item.USPlanID > 1)
                            {
                                <input type="hidden" value="@item.USPlanID.ToString()" />
                                <input type="hidden" value="@ViewBag.activeplanid" />
                                <div className="col-lg-3 col-sm-6 mt-4 mt-lg-0">
                                    <div className="subscription_box disabled">
                                        <form method="post" action="USPlanSubmit">
                                            <h3>@item.PlanName</h3>
                                            <h4>@item.PlanAmountINR INR</h4>
                                            <p>@item.PlanAmountUSD USD</p>
                                            <ul>
                                                @foreach (var item1 in item.USPLANFEATURELIST.Take(5))
                                                {
                                                    if (@item1.FeatureName == "")
                                                    {

                                                    }
                                                    else
                                                    {
                                                        <li>@item1.FeatureName <p>@Html.Raw(item1.FeatureValue)</p></li>
                                                    }

                                                }
                                            </ul>
                                            @Html.ActionLink("BUY", "USPlanSubmit", new { id = item.USPlanID }, new { @class = "btn_default" })
                                        </form>
                                    </div>
                                </div>
                            }
                            else
                            {
                                <div className="col-lg-3 col-sm-6 mt-4 mt-lg-0">
                                    <div className="subscription_box">
                                        <form method="post" action="USPlanSubmit">
                                            <h3>@item.PlanName</h3>
                                            <h4>@item.PlanAmountINR INR</h4>
                                            <p>@item.PlanAmountUSD USD</p>
                                            <ul>
                                                @foreach (var item1 in item.USPLANFEATURELIST.Take(5))
                                                {
                                                    if (@item1.FeatureName == "")
                                                    {

                                                    }
                                                    else
                                                    {
                                                        <li>@item1.FeatureName <p>@Html.Raw(item1.FeatureValue)</p></li>
                                                    }

                                                }
                                            </ul>
                                            @Html.ActionLink("BUY", "USPlanSubmit", new { id = item.USPlanID }, new { @class = "btn_default" })
                                        </form>
                                    </div>
                                </div>
                            }
                        }
                    </div>
                </div>
             */}
            </div>
        </div>

        <div className="col-md-12 mt-4">
            <div className="sec_block">
                <div className="sec_blockhead">
                    <h5>Features of Plan</h5>
                </div>
                <div className="plan_featdash">
                    <div className="row">
                        <div className="col-lg-4 col-sm-6">
                            <div className="plan_featbox">
                                <h6>
                                    Track Your Product
                                </h6>
                                <ul className="fa-ul">
                                    <li>
                                        <span className="fa-li"><i className="fas fa-check-square"></i></span> Real-time Shipment record
                                    </li>
                                    <li>
                                        <span className="fa-li"><i className="fas fa-check-square"></i></span> Current Buyers
                                    </li>
                                    <li>
                                        <span className="fa-li"><i className="fas fa-check-square"></i></span> Live Suppliers
                                    </li>
                                    <li>
                                        <span className="fa-li"><i className="fas fa-check-square"></i></span> Used HS Codes
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 mt-4 mt-sm-0">
                            <div className="plan_featbox">
                                <h6>
                                    Reports based on Analysis
                                </h6>
                                <ul className="fa-ul">
                                    <li>
                                        <span className="fa-li"><i className="fas fa-check-square"></i></span> Quantity Based Graphs
                                    </li>
                                    <li>
                                        <span className="fa-li"><i className="fas fa-check-square"></i></span> Dedicated Shipment table
                                    </li>
                                    <li>
                                        <span className="fa-li"><i className="fas fa-check-square"></i></span> Specified Product Reports
                                    </li>
                                    <li>
                                        <span className="fa-li"><i className="fas fa-check-square"></i></span> Business mapping reports
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 mt-4 mt-lg-0">
                            <div className="plan_featbox">
                                <h6>
                                    Market Trends
                                </h6>
                                <ul className="fa-ul">
                                    <li>
                                        <span className="fa-li"><i className="fas fa-check-square"></i></span> HS Codes Analysis
                                    </li>
                                    <li>
                                        <span className="fa-li"><i className="fas fa-check-square"></i></span> Daily Trade Analysis
                                    </li>
                                    <li>
                                        <span className="fa-li"><i className="fas fa-check-square"></i></span> Country Wise Reports
                                    </li>
                                    <li>
                                        <span className="fa-li"><i className="fas fa-check-square"></i></span> product Segmentation.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-12 mt-4">
                            <div className="paln_featnote">
                                <h5 className="sec_heading">Highlights of what we offer in our plans</h5>
                                <ul className="fa-ul">
                                    <li>
                                        <span className="fa-li"><i className="far fa-dot-circle"></i></span> Join our subscription plan, specially designed for the USA, built keeping in mind the market trends and requirements.
                                    </li>
                                    <li><span className="fa-li"><i className="far fa-dot-circle"></i></span> Our different plans enable you to save and download the import-export data records as per HS Code, buyer, and items. So, you can access the data on import-export trade anytime at your convenience.           </li>
                                    <li><span className="fa-li"><i className="far fa-dot-circle"></i></span>	We have customizable plans depending upon your needs. So you can pay only for just what you need. </li>
                                    <li><span className="fa-li"><i className="far fa-dot-circle"></i></span> You get the option to download data in a specific plan based on what subscription you have opted to buy.</li>
                                    <li><span className="fa-li"><i className="far fa-dot-circle"></i></span>Data is collected for the USA, extracting from official sources, allowed to be disclosed in a public domain.</li>
                                    <li><span className="fa-li"><i className="far fa-dot-circle"></i></span> 	The above prices of plans exclude  <strong>18% GST.</strong></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="col-md-12 mt-4">
            <div className="sec_block">
                <div className="sec_blockhead">
                    <h5>Payment Mode</h5>
                </div>
                <div className="row pay_dets">
                    <div className="col-lg-6 col-md-5">
                        <div className="payment_block">
                            <h6>Credit/Debit Card</h6>
                            <p>
                                <strong>Payment through cheque or Demand Draft.</strong>
                                You can send cheque or demand draft favoring “ SEAIR EXIM SOLUTIONS” payable at New Delhi at given address:- DEPT. Sales
                            </p>
                            <p>
                                <strong>SEAIR EXIM SOLUTIONS</strong>
                                Plot C2, Office No 2, First Floor, Mahindra park, Opp. GRID Office C1 Janakpuri, West Delhi 110059, India
                            </p>
                            <h6 className="mt-4">Offline Plan</h6>
                            <p>
                                <strong>Feel Free to contact us for any query.</strong>
                                <span>Call Us : <a href="tel:+91-9667735607">+91-9667735607</a></span>
                            </p>
                            <p>
                                <span>Mail Us : <a href="mailto:info@seair.co.in">info@seair.co.in</a></span>
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-7 mt-4 mt-md-0">
                        <div className="pay_acdet">
                            <h6>Account Deposit or Account Transfer</h6>
                            <ul>
                                <li>
                                    <span>
                                        Account Name
                                    </span>
                                    SEAIR EXIM SOLUTIONS
                                </li>
                                <li>
                                    <span>
                                        Bank Name
                                    </span>
                                    HDFC BANK , New Delhi
                                </li>
                                <li>
                                    <span>
                                        Account No
                                    </span>
                                    00898190000076
                                </li>
                                <li>
                                    <span>
                                        IFSC CODE
                                    </span>
                                    Hdfc0000089
                                </li>
                                <li>
                                    <span>
                                        SWIFT CODE
                                    </span>
                                    HDFCINBB
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    </section>
        </div>
        );
    }
    
  }
  